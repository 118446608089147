import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-pdf-buttons-bar',
  templateUrl: './pdf-buttons-bar.component.html'
})
export class PdfButtonsBarComponent implements OnInit {
  @Input() prevPageBtnDisabled = false;
  @Input() nextPageBtnDisabled = false;
  @Input() zoomOutBtnDisabled = false;
  @Input() zoomInBtnDisabled = false;
  @Output() prevPageBtnClick = new EventEmitter();
  @Output() nextPageBtnClick = new EventEmitter();
  @Output() zoomOutBtnClick = new EventEmitter();
  @Output() zoomInBtnClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onPrevPage(): void {
    this.prevPageBtnClick.emit();
  }

  onNextPage(): void {
    this.nextPageBtnClick.emit();
  }

  onZoomOut(): void {
    this.zoomOutBtnClick.emit();
  }

  onZoomIn(): void {
    this.zoomInBtnClick.emit();
  }
}
