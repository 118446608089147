import {Role} from './Role';
import {UserRole} from './UserRole';
import {Office} from './Office';

interface UserInterface {
  email: string;
  name: string;
  userRoles: any[];
  id: string;
  accountStatus: string;
  office: Office;
  officeId: string;
}

export class User {
  public email;
  public name;
  public userRoles;
  public id;
  public accountStatus;
  public office;
  public officeId;
  constructor({email, name, userRoles, id, accountStatus, office, officeId}: UserInterface) {
    this.email = email;
    this.name = name;
    this.id = id;
    this.accountStatus = accountStatus;
    this.userRoles = Array.isArray(userRoles) ? userRoles.filter((ur: UserRole) => ur.role !== null).map(role =>
      new UserRole({
        id: role.id,
        userId: role.userId,
        role: role.role,
        status: role.status
      })
    ) : [];
    this.office = office ? new Office({
      id: office.id,
      name: office.name,
      code: office.code,
      hasLtssBre: office.hasLtssBre
    }) : null;
    this.officeId = officeId;
  }
  public toString(): string {
    return JSON.stringify(this);
  }

  hasRole(role): boolean {
    return this.userRoles.filter((value: UserRole) =>  {
      return value.role?.roleCode === role;
    }).length > 0;
  }
}
