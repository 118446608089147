import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FileModel} from '../models/FileModel';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {DefaultApi} from '../services/DefaultApi';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorResponse, HttpEventType} from '@angular/common/http';
import Swal from 'sweetalert2';
import {Transaction} from '../models/Transaction';
import {UploadResult} from '../models/UploadResult';


@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html'
})
export class UploadComponent implements OnInit {
  @Input() dataObservable: Observable<any>;
  @Output() filesListChanged = new EventEmitter();
  private files: FileModel[] = [];
  private fileT: any;
  selectedFiles: FileList;
  filesUploaded = false;
  private allUploaded = [];
  private errorStr = '';
  public filesArray = [];
  @ViewChild('fileUpload') fileUpload: ElementRef;
  loading = false;
  index = - 1;
  ERROR_TEXT = 'The following file cannot be uploaded:<br/>';
  uploadedFiles = 0;
  isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  private zipArray = ['zip', 'rar', '7z', 'application/x-zip-compressed'];
  /*private fileBehaviorSubject = new BehaviorSubject(null);
  get fileObservable(): Observable<any> {return this.fileBehaviorSubject.asObservable(); }
  fileNamePrefix: string;*/
  transaction: Transaction;
  private finishTransactionBehaviorSubject = new BehaviorSubject<UploadResult>(null);


  constructor(private api: DefaultApi) {}

  ngOnInit(): void {
    this.filesUploaded = false;
    /*this.fileObservable.subscribe(value => {
      console.log('file = ' + value);
      if (value !== null) {
        this.uploadFiles(value);
      }
    });*/
    /*this.dataObservable.subscribe(value => {
      if (value) {
        this.transaction = value;
        this.sendTransaction();
      }
    });*/
  }

  addFiles(event): void {
    console.log('addfiles');
    this.errorStr = '';
    this.selectedFiles = event.target.files;
    this.filesUploaded = this.selectedFiles.length > 0;
    console.log('this.selectedFiles.length = ' + this.selectedFiles.length);

    for (let i = 0; i < this.selectedFiles.length; i++) {
      console.log('this.selectedFiles.item(i).size = ' + this.selectedFiles.item(i).type);
      if (this.selectedFiles.item(i).size * 0.000001  > 100) {
        this.errorStr = this.errorStr + this.selectedFiles.item(i).name + '<br/>';
        if (i === this.selectedFiles.length - 1) {
          this.showError();
        }
        continue;
      }
      if (this.zipArray.includes(this.selectedFiles.item(i).type )) {
        this.errorStr = this.errorStr + this.selectedFiles.item(i).name + '<br/>';
        if (i === this.selectedFiles.length - 1) {
          this.showError();
        }
        continue;
      }
      const fileReader = new FileReader();
      fileReader.onloadend = (evt) => {
        // console.log('evt.eventPhase  = ' + evt.eventPhase);
        // console.log('FileReader.DONE  = ' + FileReader.DONE);
        if (evt.eventPhase === FileReader.DONE) {
          const uint = Array.prototype.slice.call(new Uint8Array(fileReader.result as ArrayBuffer));
          const bytes = [];
          uint.forEach((byte) => {
            bytes.push(byte.toString(16));
          });
          const hex = bytes.join('').toUpperCase();
          console.log('hex = ' + hex);
          if (!this.isLegalType(hex) && (this.selectedFiles.item(i) as File).type !== 'text/plain') {
            this.errorStr = this.errorStr + this.selectedFiles.item(i).name + '<br/>';
          } else {
            this.filesArray.push({data: this.selectedFiles.item(i), inProgress: false, progress: 0});
            this.filesListChanged.next(this.filesArray);
            if (i === this.selectedFiles.length - 1) {
              this.fileUpload.nativeElement.value = '';
            }
          }
          if (i === this.selectedFiles.length - 1 && this.errorStr !== '') {
            this.showError();
          }
        }
      };
      const blob = this.selectedFiles.item(i).slice(0, 4);
      fileReader.readAsArrayBuffer(blob);
    }

  }

  uploadFiles(file): void {
    const tFile = file.data as File;
    this.api.uploadFile(this.transaction.id, tFile)
      /*.pipe(
        map(event => {
          console.log(JSON.stringify('!!!!!!!!!!!!!!!!!!! event.type = ' + event.type));
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round(event.loaded * 100 / event.total);
              break;
            case HttpEventType.Response:
              console.log(JSON.stringify('!!!!!!!!!!!!!!!!!!! event.body = ' + event.body));
              return event.body;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          file.success = -1;
          return of(`${file.data.name} upload failed.`);
        }))*/
      .subscribe((event: any) => {
          this.uploadedFiles = this.uploadedFiles - 1;
          /*if (typeof (event) === 'object') {
            console.log('event = ');
            // console.log(JSON.stringify('!!!!!!!!!!!!!!!!!!! event = ' + event));
            if (file.progress === 100 || event?.message !== 'Ok') {
              this.uploadedFiles = this.uploadedFiles - 1;
            }
            if (event?.message !== 'Ok') {
              // this.successUploaded++;
              this.errorStr = this.errorStr + file.data.name + '<br/>';
              file.success = -1;
            }*/
          if (this.uploadedFiles === 0) {
              this.filesArray = [];
              this.filesUploaded = false;
              this.finishTransactionBehaviorSubject.next(new UploadResult({result: 'success', error: null}));
          }
          // }
        },
        () => {
          this.uploadedFiles = this.uploadedFiles - 1;
          this.errorStr = this.errorStr + file.data.name + '<br/>';
          if (this.uploadedFiles === 0) {
            this.finishTransactionBehaviorSubject.next(new UploadResult({result: 'error', error: this.errorStr}));
          }
        });
  }

  sendAdditionalDocument(transaction: Transaction): Observable<any> {
    this.transaction = transaction;
    // this.uploadFiles();
    this.allUploaded  = [];
    this.errorStr = '';
    this.fileUpload.nativeElement.value = '';
    this.loading = true;
    this.uploadedFiles = this.filesArray.length;
    this.filesArray.forEach(value => this.uploadFiles(value));
    return this.finishTransactionBehaviorSubject.asObservable();
  }

  formatBytes(bytes, decimals): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  deleteFile(index: number): void {
    if (!this.loading) {
      this.filesArray.splice(index, 1);
      this.filesListChanged.next(this.filesArray);
    }
  }

  private isLegalType(hex: string): boolean {
    const typeList = ['89504E47', '47494638', '25504446', 'FFD8FFDB', 'FFD8FFE0', 'FFD8FFE1', 'D0CF11E0',
      '424D', '49492A0', '4D4D002A', '7B5C72746631', '504B0304', '504B34', '4D4D02A' ];
    const val = typeList.filter(type => this.matches(type, hex));
    return val.length > 0;
  }

  matches(type: string, hex: string): boolean {
    console.log('before type.includes');
    return type.includes(hex) || hex.includes(type);
  }


  private finishTransaction(): void {
    this.loading = false;
    this.index = -1;
    if (this.errorStr !== '') {
      Swal.fire({
        title: 'Failed',
        icon: 'error',
        html: 'Next files were not uploaded:<br/>' + this.errorStr
      }).then(() => this.errorStr = '');
      this.loading = false;
    } else {
      Swal.fire({
        title: 'Success',
        text: 'All files were uploaded. Do you want upload more files?',
        icon: 'success',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        allowEscapeKey: false
      }).then(swalResult => {
        if (swalResult.dismiss) {
          window.location.href = '/auth/slo';
        } else {
          this.filesArray = [];
          this.filesUploaded = false;
        }
      });
    }
  }
  showError(): void {
    // Sentry.captureMessage('User tried to add files anf could not add next files: ' + this.errorStr);
    Swal.fire({
      title: 'Failed',
      icon: 'error',
      html: this.ERROR_TEXT + this.errorStr + '<div><a href="https://cscu.service-now.com/sp/?id=kb_article&sysparm_article=KB0010015" target="_blank" style="color: red; font-weight: bold">Learn more</a></div>'
    }).then(() => {
        this.errorStr = '';
      }
    );
  }

  getDeleteClass(): string {
    if (this.loading) {
      return 'delete-file-disabled';
    }
    return 'delete-file';
  }
  getDate(date): string {
    return (date.toString().length > 1 ? date : '0' + date);
  }

  getDelay(): number {
    if (this.index === 0 ) {
      return 0;
    }
    return (this.filesArray[this.index].data.size > 10000000 || ((this.index) % 4 === 0)) ? 10000 : 0;
  }

  /*startNextFile(): void {
    console.log('index = ' + this.index);
    this.index++;
    if (this.index < this.filesArray.length) {
      setTimeout(() => {
        this.fileBehaviorSubject.next(this.filesArray[this.index]);
      }, this.getDelay());
    }
  }*/
}
