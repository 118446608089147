import {DocumentForm} from './DocumentForm';
import {TransactionDocField} from './TransactionDocField';



interface TransactionDocumentInterface {
  id: string;
  transactionId: string;
  documentFormId: string;
  documentForm: DocumentForm;
  transactionDocFields: TransactionDocField[];
  status: string;
}

export class TransactionDocument {
  public id;
  public transactionId;
  public transactionDocFields;
  public documentFormId;
  public documentForm;
  public status;
  constructor({id, transactionId, transactionDocFields, documentFormId, documentForm, status}: TransactionDocumentInterface) {
    this.id = id;
    this.transactionId = transactionId;
    this.documentFormId = documentFormId;
    // console.log('transactionDocFields = ' + JSON.stringify(transactionDocFields));
    this.transactionDocFields = Array.isArray(transactionDocFields) ? transactionDocFields.map(value =>
      new TransactionDocField({
        id: value.id,
        transactionDocumentId: value.transactionDocumentId,
        documentFormField: value.documentFormField,
        fieldValue: value.fieldValue,
        documentFormFieldId: value.documentFormField.id
      })) : [];
    this.documentForm = documentForm ? new DocumentForm({
      id: documentForm.id,
      bre: documentForm.bre,
      documentCode: documentForm.documentCode,
      documentDescription: documentForm.documentDescription,
      documentFormFields: documentForm.documentFormFields,
      isLtss: documentForm.isLtss,
      isQa: documentForm.isQa
    }) : new DocumentForm({
      id: null,
      bre: null,
      documentCode: '',
      documentDescription: '',
      documentFormFields: null,
      isLtss: false,
      isQa: false
    });
    this.status = status;
  }

  public toString(): string {
    return JSON.stringify(this);
  }

}
