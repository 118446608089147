<div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
  <!--<div class="dialog-icon" [ngClass]="'dialog-' + dialogIcon" style="display: flex;">
    <div class="dialog-icon-content">?</div>
  </div>
  <h2>{{message}}</h2>-->
  <div class="dialog-icon dialog-success" style="display: flex;" *ngIf="dialogIcon==='success'">
    <div class="dialog-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
    <span class="dialog-success-line-tip"></span> <span class="dialog-success-line-long"></span>
    <div class="dialog-success-ring"></div> <div class="dialog-success-fix" style="background-color: rgb(255, 255, 255);"></div>
    <div class="dialog-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
  </div>
  <div class="dialog-icon dialog-error dialog-icon-show" style="display: flex;" *ngIf="dialogIcon==='error'">
    <span class="dialog-x-mark" >
        <span class="dialog-x-mark-line-left"></span>
        <span class="dialog-x-mark-line-right"></span>
      </span>
  </div>
  <div class="dialog-icon dialog-question dialog-icon-show" style="display: flex;" *ngIf="dialogIcon==='question'">
    <div class="dialog-icon-content">?</div>
  </div>
  <div class="modal-dialog-message-wrap">{{message}}</div>
  <div class="dialog-buttons-wrap">
    <button mat-raised-button color="primary" (click)="onClick(showCancelBtn)">OK</button>
    <button mat-raised-button *ngIf="showCancelBtn" (click)="onClick(!showCancelBtn)" style="margin-left: 20px">CANCEL</button>
  </div>
</div>
