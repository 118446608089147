import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: [
    'styles.scss'
  ]
})
export class ModalDialogComponent implements OnInit {
  dialogIcon: string;
  message: string;
  showCancelBtn = false;

  constructor(private dialogRef: MatDialogRef<ModalDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.dialogIcon = data.icon;
    this.message = data.message;
    if (data.showCancelBtn) {
      this.showCancelBtn = data.showCancelBtn;
    }

  }

  ngOnInit(): void {
  }

  onClick(result): void {
    this.dialogRef.close(result);
  }
}
