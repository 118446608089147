import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-pdf-preview',
  templateUrl: './pdf-preview.component.html'
})
export class PdfPreviewComponent implements OnInit {
  file: any;
  currentPage = 1;
  totalPages = 1;
  scale = window.innerWidth >= 2560 ? 1.25 : window.innerWidth >= 1920 ? 1 : 0.75;

  constructor(private dialogRef: MatDialogRef<PdfPreviewComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.file = data.file;
  }

  ngOnInit(): void {
  }

  onNextPageBtnClick(): void {
    this.currentPage++;
  }

  onPrevPageBtnClick(): void {
    this.currentPage--;
  }

  onZoomInBtnClick(): void {
    this.scale = this.scale + 0.25;
  }

  onZoomOutBtnClick(): void {
    this.scale = this.scale - 0.25;
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onAfterPageRendered(event): void {
    this.totalPages = event;
  }
}
